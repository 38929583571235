import React from 'react'

function Services() {
  return (
    <div>
      <section className="services" id="Services">
      <div className="container">
        <div className="text-center">
          <h1 className="Dancing-font" data-aos="fade-up-right">My <span className="text-primary">Services</span></h1>
          <hr className="w-25 m-auto" />
        </div>
        <div className="container text-center my-5">
          <div className="row py-5">
            <div className="col-sm-6">
              <div data-aos="fade-up-right" data-aos-offset="200" className="card">
                <div className="card-body">
                  <h5 className="card-title p-2">Web development</h5>
                  <p className="card-text">Web development involves building, maintaining, and enhancing websites and web applications using a variety of technologies and frameworks.</p>
                  <a href="https:fiverr.com/hassan747" target="_blank" className="btn btn-primary">Hire Me</a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div data-aos="fade-up-left" data-aos-offset="200" className="card">
                <div className="card-body">
                  <h5 className="card-title p-2">Graphic designing</h5>
                  <p className="card-text">Graphic designing is the craft of visually communicating messages and ideas through typography, images, color, and layout techniques.</p>
                  <a href="https://fiverr.com/hassan747" target="_blank" className="btn btn-primary">Hire Me</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-sm-6">
              <div className="card" data-aos="fade-up-right" data-aos-offset="200">
                <div className="card-body">
                  <h5 className="card-title p-2">Server Hosting Expert</h5>
                  <p className="card-text">A Server Hosting Expert ensures optimal performance and security of servers for websites, applications, or data storage needs through setup, configuration, maintenance, and optimization.</p>
                  <a href="https://fiverr.com/hassan747" target="_blank" className="btn btn-primary">Hire Me</a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div data-aos="fade-up-left" data-aos-offset="200" className="card">
                <div className="card-body">
                  <h5 className="card-title p-2">Business Mail Configuration</h5>
                  <p className="card-text">Business Mail Configuration streamlines and secures email communication channels for organizations, encompassing server setup, domain configuration, and integration with email clients.</p>
                  <a href="https://fiverr.com/hassan747" target="_blank" className="btn btn-primary">Hire Me</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Services
