import React from 'react'
import Services from './components/Services'
import Header from './components/Header'
import Carousel from './components/Carousel'
import About from './components/About'
import Contact from './components/Contact'

function App() {
  return (
    <div>

      <Header />
      <Carousel />
      <About />
      <Services />
      <Contact />

    </div>
  )
}

export default App