import React from 'react'

function Header() {
  return (
    <div>
      <header id="Home">
      <section className="nav p-4">
        <nav className="navbar navbar-expand-md bg-primary fixed-top Dancing-font">
          <div className="container-fluid">
            <a className="navbar-brand text-dark" href="https://alihassan.com.pk">Digital <span className="text-light">Lab</span></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fs-5 text-center">
                <li className="nav-item"><a className="nav-link active text-light" aria-current="page" href="#Home">Home</a></li>
                <li className="nav-item"><a className="nav-link active text-dark" aria-current="page" href="#About">About</a></li>
                <li className="nav-item"><a className="nav-link active text-dark" aria-current="page" href="#Services">Services</a></li>
                <li className="nav-item"><a className="nav-link active text-dark" aria-current="page" href="#Contact">Contact</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </header>
    </div>
  )
}

export default Header
