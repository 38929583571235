import React from 'react'

function About() {
  return (
    <div>
       <section className="About p-4" id="About">
      <div className="container">
        <div className="text-center Dancing-font">
          <h1 data-aos="fade-down" data-aos-offset="200">About <span className="text-primary">Me</span></h1>
          <hr className="w-25 m-auto" />
          <div className="clearfix my-5 Dancing-font text-center">
            <img data-aos="zoom-in-right" data-aos-offset="100" src="./images/Developer Working.jpg" className="col-md-6 float-md-end mb-3 ms-md-3 w-50 img-thumbnail img-fluid" alt="Ali Hassan" />
            <p className="text-start my-5" data-aos="zoom-in" data-aos-offset="200">
            Throughout my extensive journey in graphic and web design, I've accumulated a wealth of experience that enables me to understand clients' needs and preferences with ease. My diverse background encompasses collaborations with businesses spanning various industries, allowing me to offer comprehensive design solutions tailored to your specific requirements. Whether it's crafting visually appealing websites, designing engaging email campaigns, or refining user interfaces for mobile apps, I've done it all. In my current role, I specialize in mobile app design, focusing on creating intuitive and user-friendly experiences. <br/> <br/>

My goal is to ensure that users can navigate your app effortlessly while enjoying a visually pleasing interface. <br/><br/>

What sets me apart is my collaborative approach to design. I believe in working closely with clients to ensure that their vision is translated into reality. I value your input and strive to provide you with a range of options to choose from, ensuring that the final product aligns perfectly with your expectations. With a track record of successful projects under my belt, I bring practical insights and innovative ideas to the table.<br/><br/>

I understand what works in the real world and leverage this knowledge to deliver results that exceed your expectations. In essence, I am passionate about design and dedicated to helping you achieve your goals. Let's embark on this journey together and create something extraordinary.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default About
