import React from 'react'

function Contact() {
  return (
    <div>
      <section className="Contact p-2" id="Contact">
      <div className="text-center">
        <h1 data-aos="fade-up-left" data-aos-offset="300" className="Dancing-font p-2">
          Feel Free <span className="Dancing-font text-primary">TO Contact</span>
        </h1>
        <hr className="w-25 m-auto" />
      </div>
      <div className="row p-4 text-center my-5 Dancing-font">
        <div className="col-sm-12 col-md-6 col-lg-6 col-12">
          <form className="row g-3 p-4" action="https://api.web3forms.com/submit" method="POST">
          <input type="hidden" name="access_key" value="1f367477-691d-4218-a057-bae437ccf5f7"></input>
            <div className="col-md-6">
              <label htmlFor="fname" className="form-label">First Name</label>
              <input type="text" className="form-control" placeholder="First name"  name='first_name'/>
            </div>
            <div className="col-md-6">
              <label htmlFor="lname" className="form-label">Last Name</label>
              <input type="text" className="form-control" placeholder="Last name" name='last_name' />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">Email</label>
              <input type="email" className="form-control" placeholder="you@example.com" name='email' id="inputEmail4" />
            </div>
            {/* <div className="col-md-6">
              <label htmlFor="inputPassword4" className="form-label">Password</label>
              <input type="password" className="form-control" placeholder="Abc@123" id="inputPassword4" />
            </div> */}
            <div className="col-lg-12">
              <label htmlFor="inputCity" className="form-label">Your Query</label>
              <input type="text" className="form-control" placeholder="Type Here" name='Message' id="inputCity" />
            </div>
            <div className="col-12 text-start">
              <button type="submit" className="btn btn-primary">Send Now</button>
            </div>
          </form>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-12">
          <img src="./images/Ali Hassan.jpg" alt="Ali Hassan" className="w-50 img-thumbnail p-4" />
        </div>
      </div>
      <div>
        <section>
          <nav className="bg-primary p-2 Dancing-font">
         
          <div className='footer'>
            <a className="nav-link text-light p-2 width-auto " aria-current="page" href="#Home" >All Rights Reserved</a>
            </div>
          </nav>
        </section>
      </div>
    </section>
    </div>
  )
}

export default Contact
